<template>
  <div id="page-container" class="main-content-boxed">
    <main id="main-container">
      <div class="hero bg-body-extra-light">
        <div class="hero-inner">
          <div class="content content-full">
            <div class="py-4 text-center">
              <i class="si si-ghost text-primary display-3"></i>
              <h1 class="h2 fw-bold mt-4 mb-2">QQ未绑定用户</h1>
              <h2 class="h5 fw-medium text-muted mb-5">Please return to the console.</h2>
              <a class="btn btn-primary fs-sm" href="/">
                <i class="fa fa-arrow-left opacity-50 me-1"></i> 返回控制台
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Unbounded"
}
</script>

<style scoped>

</style>
